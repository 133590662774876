import React, { Component } from 'react'
import { Home, MapPin, Mail } from 'react-feather'
import { LayoutWithOutMap, Map, SEO } from '../../components/common'
import { Container, Row, Col, Breadcrumb, Card } from 'react-bootstrap'
import ShowBlank from '../shared/ShowBlank'

class Contact extends Component {
   render() {
    const { topNav, embedFormsPageData } = this.props.pageContext
    const { favicon, address, email } = topNav 
    const embedForms = embedFormsPageData && embedFormsPageData.length > 0? embedFormsPageData : [] 
    return (
      <>
        <SEO title="Contact Us" favicon={favicon}/>
        <LayoutWithOutMap activeNav='contact-us' data={topNav}>
          <Container className="my-4 md-mt-10 sm-mt-7">
            <Breadcrumb className="breadcrumb-wrapper mx-0">
              <Breadcrumb.Item href="/">
                <Home className="text-violet mr-2" size={14} />
                Home
            </Breadcrumb.Item>
              <Breadcrumb.Item active>Contact Us</Breadcrumb.Item>
            </Breadcrumb>

            <Row>
              <Col lg={6} md={12} sm={12} className="my-3 justify-content-center">
                <h4>Contact Us</h4> 
                <div className="d-flex flex-row pt-4 md-pt-3 sm-pt-0 lg-pt-3">
                   <MapPin size={30} className="text-danger map-pin" />
                   <div className="d-flex flex-column ml-3 mt-1 sm-mt-0 md-mt-1 lg-mt-1">
                      <p className="text-primary m-0">ADDRESS</p>
                      <p className="text-info f_10px">{address || 'No Data Available'}</p>
                   </div>
                 </div>
  
                <div className="d-flex flex-row pt-3 md-pt-3 sm-pt-0 lg-pt-3">
                  <Mail size={30} className="text-danger map-pin" />
                  <div className="d-flex flex-column ml-3 mt-1 sm-mt-0 md-mt-1 lg-mt-1">
                      <p className="text-primary m-0">EMAIL ADDRESS</p>
                      <p className="text-info f_10px">{email || 'No Data Available'}</p>
                   </div>
                </div>
              </Col>
              <Col lg={6} md={12} sm={12} className="my-3 justify-content-center">
                  <Map data={topNav} />  
              </Col>
            </Row>

            <h4 className="mt-5">Give us a Feedback</h4> 
              <Row>
                {
                  embedForms.length !== 0?
                    embedForms.map((data, index) =>
                      <Col lg={6} md={12} sm={12} className="my-3 justify-content-center mb-3" key={index}>
                        <Card body className="h-100"> 
                          <a href={data.embeddedCode}  className="text-decoration-none"> 
                            <h6>{data.title}</h6> 
                          </a> 
                          <p className="mb-0">{data.shortDescription}</p>
                        </Card>
                      </Col>
                    )
                  : <ShowBlank />
                }
            </Row>
          </Container>
        </LayoutWithOutMap>
      </>
    )
  }
}

export default Contact
